import {pages, pagesList} from './pages';

export const router = {
    goToPage(page: string) {
        const data = pages[page];
        this.loadPage(data);
    },
    loadPage(data) {
        const container = document.getElementById('pages');
        console.log(container);
        (container as Element).innerHTML = data.template;
        data.script && data.script();
    }
}

const onLocationChange = href => {
    const target = pagesList.find(p => !!href.toString().match(p.path));
    console.log(href, target);
    if (target) {
        router.loadPage(target);
    }
};

// window.addEventListener('locationchange', href => {
// });

window.onload = () => {
    onLocationChange(location.pathname);
};

