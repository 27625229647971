export const pages = {
    feats: {
        path: '/feats/pp',
        title: 'Feats: Privacy Policy',
        template: require('./pages/feats/pp.html')
    },
    terms: {
        path: '/feats/terms',
        title: 'Feats: Terms of Usage',
        template: require('./pages/feats/terms.html')
    },
    main: {
        path: '/',
        title: 'GLBP',
        template: require('./pages/main.pug'),
        script: () => {
            import('./main')
        }
    },
}

export const pagesList = Object.keys(pages).map(k => pages[k]);
